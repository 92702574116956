.text-depositions p {
	padding-left: 10px;
	padding-right: 10px;
	font-weight: lighter;
	margin-top: 10px;
	font-family: 'open sans', sans-serif;
	font-size: 14px;
	font-style: italic;
}

.author-depositons h5 {
	color: #004A8F;
}

.image-depositions img {
	border-radius: 100%;
	height: 150px;
	width: 150px;
}

.swiper-button-prev {
	background-image: url('../images/previous.png');
	background-size: 100%;
	width: 64px;
}

.swiper-button-next {
	background-image: url('../images/next.png');
	background-size: 100%;
	width: 64px;
}

* {
	box-sizing: border-box
}

#depositions-slide {
	position: relative;
}


.swiper-button-next {
	opacity: 0;
}

.swiper-button-disabled {
	opacity: 0 !important;
}

.swiper-container:hover>.swiper-button-next {
	transition: 1s ease-out;
	opacity: 1;
}

.swiper-button-prev {
	opacity: 0;
}

.swiper-container:hover>.swiper-button-prev {
	transition: 1s ease-out;
	opacity: 1;
}

.swiper-container:hover>.swiper-button-disabled {
	transition: 1s ease-out;
	opacity: 0.35 !important;
}


@media (min-width: 37.5em) {
	#depositions-slide {
		margin-left: auto;
		margin-right: auto;
		width: 92%;

		padding-left: 2em;
		padding-right: 2em;
	}
}

@media (min-width: 43.75em) {
	#depositions-slide {
		width: 100%;
	}
}




.swiper-container {
	margin-top: 2em;
}

.swiper-wrapper {
	width: 73.8%;
}

@media (min-width: 37.5em) {
	.swiper-wrapper {
		width: 100%;
	}
}



@media (min-width: 37.5em) {
	.swiper-slide.swiper-slide {
		width: 50%;

	}
}


@media (min-width: 53em) {
	.swiper-slide.swiper-slide {

		width: 25%;

		/*transform: translateX(-150%);*/
	}
}

.swiper-button-prev {
	display: none;

	left: -10px;
	top: 0;


	height: calc(100% - 3em);
}

@media (min-width: 37.5em) {
	.swiper-button-prev {

		display: block;
	}
}

.swiper-button-next {
	display: none;

	right: -10px;
	top: 0;

	height: calc(100% - 3em);
}

@media (min-width: 37.5em) {
	.swiper-button-next {

		display: block;
	}
}
