img[src$='.svg'] {
    display: none;
}

svg g,
svg path {
    fill: inherit;
}

/*.btn {
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
}*/

.dropdown-item.active,
.dropdown-item:active {
    background-color: #FFB700;
}

.shadow {
    box-shadow: 0 .1rem 1rem rgba(0, 0, 0, .1) !important;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
    height: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn-custom {
    padding: .3rem 1rem;
    margin-top: .1rem;
    color: #fff;
    background-color: #FFB700;
    border-radius: 9px;
}

.btn-custom:hover {
    color: #fff;
    background-color: #FECF59;
    border-radius: 9px;
    transition: 0.5s;
}

.click-icon {
    max-width: 15px;

}

.swal-footer {
    text-align: center;
}

#return-to-top {
    background: #000;
    background: rgba(0, 0, 0, .7);
    border-radius: 35px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    line-height: 50px;
    width: 50px;
    height: 50px;
    display: none;
    position: fixed;
    bottom: 10px;
    right: 10px;
    transition: all .3s ease;
    z-index: 3;
}

/*.title-product{
    color: #432e53;
    border-bottom: 1px solid #432e53; padding: 1rem;
}*/
