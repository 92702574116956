#departamento .carousel-indicators {
    margin-bottom: -20px !important;
}

#departamento .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.7) !important;
}

#departamento .carousel-indicators li.active {
    background-color: rgba(0, 0, 0, 1) !important;
}

#departamento .custom-control-label {
    cursor: pointer;
}

@media (max-width: 767.98px) {

    body .filtro-overlay {
        position: absolute;
        z-index: 1031;
        left: 0;
        top: 0;
        right: 0;
        min-height: 100vh;
        background-color: rgba(0, 0, 0, .2);
    }

    #departamento .filtros {
        position: fixed;
        width: 300px;
        height: 100%;
        overflow-y: scroll;
        z-index: 1032;
        background-color: white;
        overflow-x: hidden;
        padding: 0;
        top: 0;
        left: -300px;
        transition: all 100ms;
        margin: 0 !important;
    }

    #departamento .filtros.open {
        box-shadow: 0 0 1rem 1rem rgba(0, 0, 0, .2);
        padding: 1.5rem;
        left: 0;
    }
}