#news-title h1 {
	color: #333;
}

#news-title {
	padding-top: 2rem;
	padding-bottom: 1rem;
}

#news-text p {
	font-size: 16px;
	font-family: 'Roboto Slab', serif;
}

#news-image img {
	width: 100%;
	max-height: 500px;
}

.card-title {
	color: #333;
}

.card-subtitle {
	color: #004A8F;
}

.text-blog {
	height: 200px;
}