#attendance {
	background-color: #FFB700;
}

#attendance h3 {
	color: rgba(0, 0, 0, 0.9);
	margin-bottom: 0px;
}

#attendance p {
	color: #333333;
	margin-bottom: 0px;
}

.btn-custom-white {
	padding: .7rem 1rem;
	background-color: #fff;
	color: rgba(0, 0, 0, 0.9);
}

.btn-custom-white img {
	margin-right: 0.2rem !important;
}

.btn-custom-white:hover {
	background: #dddcdc;
	transition: 1s;
}