.blog-span {
	color: #004A8F;
	font-weight: 500;
}

.text-blog {
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	padding-bottom: 1.5rem;
	text-align: left;
	height: 200px;
}

.text-blog h3 {
	margin-top: 0.5rem;
}

.img-blog img {
	width: 100%;
}

.btn-custom-square {
	padding: .7rem 1rem;
	background-color: #FFB700;
	color: rgba(0, 0, 0, 0.9);
}

.btn-custom-square img {
	width: 20px;
}

.btn-custom-square:hover {
	background: #FECF59;
	transition: 1s;
}
