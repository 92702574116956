html,
body {
    background-color: #fff;
    font-family: 'roboto', sans-serif;
    height: 100%;
    font-size: 16px;
}

html {
    scroll-behavior: smooth;
}

section {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

h1 {
    color: #004A8F;
    font-size: 48px;
    font-weight: bold;
}

.text-blue {
    color: #004A8F;
}

h5 {
    font-size: 17px;
}

a {
    color: #000;
}

a:hover {
    color: #090909;
}

a:hover,
a:focus,
a:active,
button:hover,
button:focus,
button:active {
    text-decoration: none;
}

img {
    max-width: 100%;
}

p {
    color: #999999;
    font-size: 14px;
    font-weight: lighter;
}

.card-img-top {
    width: 60%;
    padding-top: 2rem;
}

.p-bg {
    font-size: 18px;
    font-weight: 220;
}

@media (max-width: 992px) {
    p {
        font-size: 14px;
    }

}