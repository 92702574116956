.links-header {
	display: flex;
	font-weight: 500;
	line-height: 1.2rem;
	font-size: 17px;
	align-items: center;
}

.links-header img {
	border-radius: 100%;
	height: 60px;
	width: 60px;
	margin-right: 10px;
}

.link-card {
	height: 210px;
}
