.page-link {
    color: #ffb700;
}

.page-link:hover {
    color: #ffb700;
}

.page-item.active .page-link {
    color: #fff;
    border-color: #ffb700;
    background-color: #ffb700;
}
