.under-line {

	width: 70%;
	float: left;
	border-bottom: 1.5px solid #ffb700;
}

.image-products img {
	max-width: 100px;
}

.category-image {
	text-align: center;
	height: 100px;
}

.category-image img {
	width: 80px;
}

.category-description {
	text-align: center;
}