.whatsapp-element {
	position: fixed;
	z-index: 1030;
	right: 30px;
	left: auto;
	bottom: 30px;
}

.whatsapp-button {
	border: none;
	border-radius: 100%;
	background: #00e676;
	width: 65px;
	height: 65px;
	font-size: 40px;
	color: #fff;
}

.whatsapp-button:hover {
	color: #fff;
}