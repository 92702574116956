#main_slide {
	padding-top: 0;
}

.swiper-pagination-bullet {
	background: #DBDBDB;
	border-radius: 31%;
	width: 9px;
	height: 9px;
}

.swiper-slide img {
	width: 100%
}

.swiper-pagination-bullet-active {
	background: #FFB700;
}


@media (max-width: 992px) {
	#main_slide {
		padding-top: 2rem;
	}
}