.logo-footer {
	margin-bottom: 5px;
}

.page-footer-info {
	padding-top: 3rem;
	/* background-color: #ffb700; */
	background-color: #004990;
}

.page-footer-info .address {
	background-color: #004990;
}

.page-footer-info .addres-item {
	font-weight: lighter;
	color: #fff;
}

.page-footer-info .addres-item a {
	color: #fff;
}

.copyright-bar {
	font-weight: lighter;
	color: #fff;
	font-size: 1rem;
	padding-top: 1.2rem;
	padding-bottom: 1.2rem;
	/* background-color: #E6A500; */
	background-color: #003870;
}


.footer-info {
	margin-bottom: 1.5rem;
	display: flex;
}

.img-footer {
	margin-right: 0.5rem;
}

.img-footer img {
	min-width: 18px;
}

.footer-links {
	padding-top: 2.5rem;
	color: #fff;
}

.footer-links p a{
	/* color: #212529; */
	color: #fff;
}

.footer-links a:hover {
	text-decoration: underline;
}


@media (max-width: 768px) {
	.footer-links {
		padding-top: 3rem;
	}
}