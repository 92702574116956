#section_map {
	display: flex;
}

.address {
	padding: 5rem;
	width: 50%;
	background-color: #ffb700;
	/* background-color: #004990; */
}

.address h2 {
	color: #333333;
	/* color: #fff; */
}

.address img {
	margin-right: 1rem;
	width: 22px;
	height: 21px;
}

.addres-item {
	font-weight: lighter;
	color: #333333;
}

.addres-item a {
	color: #333333;
}

.address p {
	line-height: 1.5rem;
	color: #333333;
	/* color: #fff; */
}

@media (max-width: 992px) {
	.address {
		width: 100%;
		padding: 2rem;
	}

	#section_map {
		display: block;
	}
}