.info-bar {
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	font-size: .7rem;
	/* background-color: #FFB700; */
	background-color: #004990;
	color: #fff;
}

.info-bar a {
	color: #fff;
}

.navbar {
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
}

.navbar-collapse {
	flex-grow: 0;
}

.fixed-top {
	-webkit-box-shadow: 0px 1px 8px 0px rgba(110, 110, 110, 0.2);
	-moz-box-shadow: 0px 1px 8px 0px rgba(110, 110, 110, 0.2);
	box-shadow: 0px 1px 8px 0px rgba(110, 110, 110, 0.2);
}

.navbar-nav .nav-item {
	margin-right: 1rem;
	border-bottom: 2px solid #fff;
	font-size: 14px;

}

.navbar-nav .nav-item:hover {
	transition: 0.5s;
	border-bottom-color: #FFB700;
}

.navbar-nav .nav-item:last-child {
	margin-right: 0px;
}

.navbar-nav .nav-item:last-child:hover {
	border-bottom-color: #fff;
}

.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light {
	background-color: #fff;
}

.navbar-light .navbar-nav .nav-link:hover {
	color: #FFB700;
	transition: 0.5s;
}

@media (max-width: 992px) {
	.navbar-nav .nav-item {
		border-bottom: none;
	}
}