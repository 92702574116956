.circle-img-lg {
	width: 60%;
}

.circle-img-sm {
	width: 40%;
	height: auto;
}

.company-img {
	filter: drop-shadow(0px 0px 7px rgba(0,0,0,.3));
}